import * as types from '../constants/actions/auth';
import { showLoading, hideLoading, showMessageBar } from '../actions/app';
import moment from 'moment';
import { rsaEncrypt } from '../helpers/auth';
import { apiSignInUser, apiCheckSignUpUser as checkSignUp, fetchStates,
  apiTermsNConditions, apiSocialSignUp, apiVerifyUser, apiVerifyPhone } from '../api/auth';
import { isEmail } from 'validator';
import { Application } from '../core/app';
import { INTERNAL_ERROR } from '../constants/errors';

export function userSignedIn(payload) {
  return {
    type: `${types.SIGN_IN}_FULFILLED`,
    payload
  };
}

export function signUpFulfilled() {
  return {type: `${types.SIGN_UP}_FULFILLED`};
}

export function socialSignUpFulfilled(message) {
  return {
    type: `${types.SOCIAL_SIGN_UP}_FULFILLED`,
    payload: message
  };
}

export function otpFormFalse() {
  return {
    type: types.OTP_FORM_FALSE,
  };
}

/**
 * Attempt to authenticate a user.
 * 
 * @param {*} data 
 */
export function userSignIn (data) {
  const { user, password } = data;
  const encryptedPass = rsaEncrypt(password);
  const newData = { 
    password: encryptedPass, 
    type: 3
  };

  if (isEmail(user)) {
    newData.email = user
  } else {
    newData.phoneno = user;
  }

  return checkSocialExistance(newData);
}

export function socialSignIn(data) {
  let newData = {
    email: data.email,
    password: false,
    socialId: data.socialId,
    type: data.type
  }

  return checkSocialExistance(newData, true);
}

export function setAuthUserData(data) {
  return {
    type: types.SET_AUTH_USER_DATA,
    payload: data
  };
}

export function checkUserSignUp(data) {
  const { email, fname, lname, stateid, language, phoneno, ssn, dob, type, password } = data;
  const encryptedPass = rsaEncrypt(password);
  const newData = {
    email,
    fname,
    lname,
    stateid,
    phoneno,
    type,
    password: encryptedPass,
    dob: dob ? moment(dob).format('MM/DD/YYYY').toString() : null,
    ssn: ssn ? ssn : null,
    language
  };
  
  var termsBodyRegex = /<body>([\S\s]+)<\/body>/m;

  return function (dispatch) {
    dispatch(showLoading());
    
    return checkSignUp(newData)
      .then(function({ data: { status, message } }) {
        if(status === 0) {
          dispatch(showMessageBar({
            status: 'error',
            text: message
          }));
          
          return false;
        }
        
        return true;
      })
      .then(function(shouldRequestTerms) {
        if(shouldRequestTerms) {
          return apiTermsNConditions(stateid);
        }

        return false;
      })
      .then(function(resp) {
        dispatch(hideLoading());

        if (!resp) {
          return false;
        }

        const { data } = resp;
        const body = data.match(termsBodyRegex)[1];
        
        return body;
      })
      .catch((error) => {
        return Promise.reject(error);
      })
  }
}

export function userLogOut () {
  Application.logout();

  return {
    type: types.LOG_OUT
  }
}

export function getStates() {
  return {
    type: types.GET_STATES,
    payload: fetchStates()
  }
}

export function getSocialSignUp(formValues) {
  const { fname, lname, phoneno, email, ssn, dob, language, stateid, password, type } = formValues;
  const encryptedPass = rsaEncrypt(password);
  const newData = {
    fname,
    lname,
    phoneno,
    email,
    stateid,
    type,
    password: encryptedPass,
    dob: dob ? moment(dob).format('MM/DD/YYYY') : null,
    ssn: ssn ? ssn : null,
    language
  };

  if (formValues.type !== 3) {
    newData.socialId = formValues.socialId;
  }

  return function(dispatch) {
    dispatch(showLoading());

    return apiSocialSignUp(newData)
      .then(function(response) {
        let { data: { status, message } } = response;

        dispatch(hideLoading());
        
        if(status === 0) {
          dispatch(showMessageBar({
            status: 'success',
            text: message
          }));

          return false;
        } else {
          dispatch(socialSignUpFulfilled({formValues: newData, message}));
          return true;
        }
      })
      .catch(() => {
        dispatch(showMessageBar({
          status: 'error',
          text: INTERNAL_ERROR
        }));
      });
  }
}

/**
 * Check the user's data and if it is correct register the user.
 * 
 * @param values - The user data.
 */
export function verifyUser(values) {
  return function (dispatch) {
    dispatch(showLoading());
    
    return apiVerifyUser(values)
      .then(function({ data: { result, message, status } }) {
        dispatch(hideLoading());

        if(status === 1 && result.accessToken && result.accessToken.length > 0) {
          // Set the accessToken and configure it on axios configuration.
          Application.setAccessToken(result.accessToken);
          Application.setAuthUserData(result.data);
          window.axios.defaults.headers.common['accessToken'] = Application.getAccessToken();
          
          dispatch(otpFormFalse());
          dispatch(userSignedIn(true));

          return true;
        // In case the status is 1 and the accesstoken isn't in the response, it means the email needs to be verified before login
        } else if (status === 1 && result.accessToken === undefined) { 
          dispatch(showMessageBar({
            status: 'error',
            text: message
          }));

          return 'verified_email_required';
        } else {
          dispatch(showMessageBar({
            status: 'error',
            text: message
          }));
          
          return false;
        }
      })
      .catch(() => {
        dispatch(hideLoading());
        dispatch(showMessageBar({
          status: 'error',
          text: INTERNAL_ERROR
        }));
      });
  }
}

export function verifyPhone(values) {
  return function (dispatch) {
    dispatch(showLoading());
    return apiVerifyPhone(values)
      .then(function ({ data: { result, message, status } }) {
        dispatch(hideLoading());
        if (status === 1) {
          return true;
        } else {
          dispatch(
            showMessageBar({
              status: "error",
              text: message,
            })
          );
          return false;
        }
      })
      .catch(() => {
        dispatch(hideLoading());
        dispatch(
          showMessageBar({
            status: "error",
            text: INTERNAL_ERROR,
          })
        );
      });
  };
}

export function setMyInfoSocialNetwork(payload) {
  return {
    type: types.SET_SOCIAL_LOGIN,
    payload
  }
}

/**
 * Check if the user credentials are corrected an authenticate the user.
 * 
 * @param {*} data 
 * @param {*} isSocialLogin 
 * @param {*} dispatch 
 */
function checkSocialExistance(data, isSocialLogin = false) {
  return function (dispatch) {
    dispatch(showLoading());

    return apiSignInUser(data)
      .then(function({data: {result, message, status, statusCode}}) {
        dispatch(hideLoading());

        if (status === 1 && statusCode === 200) {
          if (isSocialLogin && result.isNewUser) {
            return false;
          }

          if (result.accessToken && result.accessToken.length > 0) {
            // Set the accessToken and configure it on axios configuration.
            Application.setAccessToken(result.accessToken);
            Application.setAuthUserData(result.data);
            window.axios.defaults.headers.common['accessToken'] = Application.getAccessToken();
  
            dispatch(userSignedIn(true));
            
            return true;
          // In case the status is 1 and the accesstoken isn't in the response, it means the email needs to be verified before login
          } else if (result.accessToken === undefined && result.reason !== undefined) {
            dispatch(showMessageBar({
              status: 'error',
              text: message
            }));
            return result.reason;
          }
        }

        dispatch(showMessageBar({
          status: 'error',
          text: message
        }));
      })
      .catch(function() {
        dispatch(hideLoading());
      });
  };
}
