import React, { Component } from 'react';
import AddPhoneForm from '../../settings/add-phone-form';
// import { requestAddPhone } from '../../../api/client';
// import { INTERNAL_ERROR } from '../../../constants/errors';
// import {showMessageBar} from '../../../actions/app';

class AddPhone extends Component {
  addPhone = (values) => {
    this.props.showLoading();
    values.phone = values.phone.substring(1);

    //Process when the number needs to be validated to add to the database
    this.props.onSuccess(null, values);

    //Process when the number was added and then it was validated
    // requestAddPhone(values)
    // .then((response) => {
    //     this.props.hideLoading();

    //     let data = response.data;

    //     if (data.status === 1 && data.statusCode === 200) {
    //       this.props.onSuccess(data, values);
    //     } else {
    //       this.props.onError(data.message);
    //     }
    // })
    // .catch((e) => {
    //     console.log(INTERNAL_ERROR);
    //     this.props.hideLoading();

    //     showMessageBar({
    //         status: 'error ' + e.toString(),
    //         text: INTERNAL_ERROR
    //     });
    //     // this.props.onError(INTERNAL_ERROR);
    // });
}

  /**
   * Render the component view.
   */
  render() { 
    return (
      <div>
        <div className="modal__header">
          <h3 className="modal-title">{ this.props.whiteLabel.labels['Add Phone Number'] }</h3>
        </div>

        <div className="modal__body">
          <div className="modal__body-content--pad35 text-center">
            <AddPhoneForm whiteLabel={this.props.whiteLabel} onSubmit={this.addPhone} />
          </div>
        </div>
      </div>
    )
  }
}
 
export default AddPhone;
