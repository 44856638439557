import React, { Component } from 'react';
import { STRONG_PASSWORD } from '../../constants/regex';

// Import components
import { Field, reduxForm } from 'redux-form';
import PayixButton from '../../containers/payix-button';

const validate = (values, _parent) => {
  const errors = {};

  if (!values.newPassword) {
    errors.newPassword = _parent.whiteLabel.labels['This field is required'];
  }
  
  if (!values.confirmPassword || values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = _parent.whiteLabel.labels['Password and password confirmation must match'];
  }

  if(!STRONG_PASSWORD.test(values.newPassword)) {
    errors.newPassword = _parent.whiteLabel.labels['Must contain one lower & uppercase character, one number, one special & must be 8-50 characters long'];
  }

  return errors;
};

const renderField = ({input, name, maxLength, meta: { touched, error }}) => (
  <div>
    <input 
      className="form-group__input" id={name} {...input} maxLength={maxLength}
      type="password" autoComplete="off" />
    { touched && error && <span className="form-group__error">{error}</span> }
  </div>
)

class ChangePasswordForm extends Component {
  /**
   * Render the component view.
   */
  render() {
    let { handleSubmit, valid } = this.props;

    return (
      <React.Fragment>
        <h2 className="settings-card__title">{this.props.whiteLabel.labels['Change Password']}</h2>
        <hr className="hr" />

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="form-group__label" htmlFor="oldPassword">{this.props.whiteLabel.labels['Old Password']}</label>
            <Field name="oldPassword" component={ renderField } />
          </div>

          <div className="form-group">
            <label className="form-group__label" htmlFor="newPassword">{this.props.whiteLabel.labels['New Password']}</label>
            <Field name="newPassword" component={ renderField } />
          </div>

          <div className="form-group">
            <label className="form-group__label" htmlFor="confirmPassword">{this.props.whiteLabel.labels['Confirm New Password']}</label>
            <Field name="confirmPassword" component={ renderField } />
          </div>

          <div className="form-group">
            <PayixButton className="button--full" type="submit" disabled={!valid}>
                {this.props.whiteLabel.labels['Save Changes']}
            </PayixButton>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

ChangePasswordForm = reduxForm({
  form: 'changePasswordForm',
  validate
})(ChangePasswordForm)
 
export default ChangePasswordForm;