import React, { Component } from 'react';
import { Application } from '../core/app';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addCard, addAch } from '../actions/client';

// Import components
import AddCardForm from './add-card-form';
import AddAchForm from './add-ach-form';
import AddPadForm from './add-pad-form';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// Import styles
import 'react-tabs/style/react-tabs.css';
class AddPaymentMethodModal extends Component {
  state = {
    title: '',
    description: ''
  }

  componentDidMount() {
    if((this.props.countryCode === 'CA' ? this.props.enableAch : false)
        && this.props.firstTabByDefaultCard !== '1'){
      this.setPadFormDescription();
    } else if (this.props.enableCard && this.props.firstTabByDefaultCard === '1') {
      this.setCardFormDescription();
    } else {
      this.setAchFormDescription();
    }
  }

  /**
   * Set the title and description on card ach.
   */
  setCardFormDescription() {
    this.setState({
      title: this.props.whiteLabel.labels['Add Card Title'],
      description: this.props.whiteLabel.labels['Add Card Description']
    });
  }

  /**
   * Set the title and description on create ach.
   */
  setAchFormDescription() {
    this.setState({
      title: this.props.whiteLabel.labels['Add Bank Account Title'],
      description: this.props.whiteLabel.labels['Add Bank Account Description']
    });
  }

  /**
   * Set the title and description on create pad.
   */
  setPadFormDescription() {
    this.setState({
      title: this.props.whiteLabel.labels['Add Bank Account (CA) Title'],
      description: this.props.whiteLabel.labels['Add Bank Account (CA) Description']
    });
  }

  /**
   * Controls the event each time the tab is changed.
   */
  handleSelectedTab = (index, last, event) => {
    if (event.target.dataset.type === 'card' 
        && (this.props.countryCode === 'CA' ? this.props.enableAch : false)) {
        this.setPadFormDescription();
    } else if (event.target.dataset.type === 'card') {
      this.setCardFormDescription();
    } else if (event.target.dataset.type === 'bank') {
      this.setAchFormDescription();
    } else if (event.target.dataset.type === 'bank-ca') {
      this.setPadFormDescription();
    }
  }

  /**
   * Add a new card.
   */
  addCard = (values) => {
    values.expiryDate = values.expirationMonth + '/' + values.expirationYear;

    this.props.addCard(values)    
      .then((response) => {
        let data = response.data;

        if (data.status === 1) {
          this.props.onSuccess();
          if (typeof this.props.changePayment === 'function') {
            this.props.changePayment(0, true);
          }
        }
      })
      .catch(() => {
        Application.showInternalError();
      })
  }

  /**
   * Add a new ach account.
   */
  addAch = (values) => {
    this.props.addAch(values)    
      .then((response) => {
        let data = response.data;

        if (data.status === 1) {
          if (typeof this.props.onSuccess === "function") {
            this.props.onSuccess();
          }
          if (typeof this.props.changePayment === "function") {
            this.props.changePayment(0, true);
          }
        }
      })
      .catch(() => {
        Application.showInternalError();
      })
  }

  /**
   * Render the bank account ACH or PAD tab.
   */
   renderBankAccountTab() {
    let enableAch = this.props.enableAch ? true : false;

    if (!enableAch) {
        return ''
    }

    if (this.props.countryCode === 'CA') {
        return (
            <Tab data-type='bank-ca'>{this.props.whiteLabel.labels['Bank Account (CA)']}</Tab>
        )
    }

    return (
        <Tab data-type='bank'>{this.props.whiteLabel.labels['Bank Account']}</Tab>
    )

  }

  /**
   * Render the card tab.
   */
   renderCardTab() {
    let enableCard = this.props.enableCard ? true : false;
    let hideCardsForBorrowers = this.props.hideCardsForBorrowers;

    if (!enableCard || hideCardsForBorrowers === '1') {
        return ''
    }
    return (
        <Tab data-type='card'>{this.props.whiteLabel.labels['CARD']}</Tab>
    )
  }

  /**
   * Render the bank account ACH/PAD tab content.
   */
   renderBankAccountTabContent() {

        if (this.props.countryCode === 'CA') {
            return (
                <TabPanel>
                    <AddPadForm onSubmit={ this.addAch } whiteLabel={this.props.whiteLabel} />
                </TabPanel>
            )
        }

        let enableAch = this.props.enableAch ? true : false;

        if (!enableAch) {
            return ''
        }
        
        return (
            <TabPanel>
                <AddAchForm onSubmit={ this.addAch } whiteLabel={this.props.whiteLabel} />
            </TabPanel>
        )
  }

  /**
   * Render the card tab content.
   */
   renderCardTabContent() {
    let enableCard = this.props.enableCard ? true : false;

    if (!enableCard) {
        return ''
    }
    return (
        <TabPanel>
            <AddCardForm onSubmit={ this.addCard } whiteLabel={this.props.whiteLabel} />
        </TabPanel>
    )
  }

  /**
   * Render all tabs.
   */
   renderTabs() {
    
    // Show CARD tab first if firstTabByDefaultCard is ON
    if (this.props.firstTabByDefaultCard === '1') {
        return (
            <React.Fragment>
                { this.renderCardTab() }
                { this.renderBankAccountTab() }
            </React.Fragment>
        )
    }

    // Show CARD tab last if firstTabByDefaultCard is OFF
    return (
        <React.Fragment>
            { this.renderBankAccountTab() }
            { this.renderCardTab() }
        </React.Fragment>
    )

   }

  /**
   * Render all tab content.
   */
   renderTabContent() {

    // Show CARD content first if firstTabByDefaultCard is ON
    if (this.props.firstTabByDefaultCard === '1') {
        return (
            <React.Fragment>
                { this.renderCardTabContent() }
                { this.renderBankAccountTabContent() }
            </React.Fragment>
        )
    } 

    // Show ACH content first if firstTabByDefaultCard is OFF
    return (
        <React.Fragment>
            { this.renderBankAccountTabContent() }
            { this.renderCardTabContent() }
        </React.Fragment>
    )
   }

  /**
   * Render the component view.
   */
  render() {
    return (  
      <div>
        <div className="modal__header">
          <div className="modal__note-wrapper">
            <h3 className="payment-methods__title">{ this.state.title }</h3>
            <p className="payment-methods__description">{ this.state.description }</p>
          </div>
        </div>

        <div className="modal__body">
          <Tabs onSelect={ this.handleSelectedTab }>
            <TabList>
              { this.renderTabs() }
            </TabList>
              { this.renderTabContent() }
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    enableAch: state.client.data.isAchMobile,
    enableCardCanada: state.client.data.hideCardIfCa,
    firstTabByDefaultCard: state.client.data.firstTabByDefaultCard,
    enableCard: state.client.data.cardEnable,
    countryCode: state.auth.userData.tblState ? state.auth.userData.tblState.countryCode : 'US',
    whiteLabel: state.whiteLabel
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    addCard, addAch
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentMethodModal);