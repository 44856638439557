import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reset } from 'redux-form';
import Statements from './statements';
import Documents from './documents';

// Import actions
import { showLoading, hideLoading, showMessageBar } from '../actions/app';


// Import styles
import '../styles/modules/statements/index.css';

class StatementsAndDocuments extends Component {

  state = {
    selected: 0,
  }

  /**
   * Handle the event when the user change the loan account.
   * 
   * @param event
   */
  handleSelectOption = (event) => {
    this.setState({
        selected: parseInt(event.target.value)
    });

  }

  /**
   * Render the component view.
   */
  render() {
    return (
      <div className="content">
        <div className="flex-block flex-block--space-between">
          <div className="contact-info__loan-statements">
            <label className="contact-info__loan-accounts-label">{this.props.whiteLabel.labels['Select Action']}</label>
            <select
              className="custom-select-in"
              onChange={this.handleSelectOption}>
                <option key="0" value="0">
                  {this.props.whiteLabel.labels['Statements']}
                </option>
                <option key="1" value="1">
                  {this.props.whiteLabel.labels['Documents']}
                </option>
            </select>
          </div>
        </div>
        <div className="app-statements">
          {this.state.selected === 0 && (<Statements/>)}
          {this.state.selected === 1 && (<Documents/>)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    whiteLabel: state.whiteLabel
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    showLoading, hideLoading, showMessageBar, reset
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StatementsAndDocuments));