import React, { Component } from "react";

class PasswordShowHide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
      password: ""
    };

    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  componentDidMount() {
    if (this.props.password) {
      this.setState({ password: this.props.password });
    }
  }

  render() {
    const { className, inputName, label, htmlFor, autoComplete} = this.props;
    return (
      <div className="form-group">
        <label htmlFor={htmlFor} className="password">
          <input 
            {...this.props.input}
            {...this.props.rest}
            type={this.state.hidden ? "password" : "text"}
            autoComplete={autoComplete ? autoComplete : "off"}
            id={htmlFor} 
            name={inputName} 
            className={className} 
          />
          <span toggle="#password-field" onClick={this.toggleShow} className="fa fa-lg fa-eye field-icon toggle-password"></span>
        </label>
      </div>
    );
  }
}

export default PasswordShowHide;
