import React, { Component } from 'react';

// Import styles
import '../../styles/modules/admin-home/account-details.css';

class AccountDetails extends Component {
  /**
   * Render the component view.
   */
  render() {
    let { loanAccount, paidOff, onShowDocument = false } = this.props;
    return (
      <div>
        {loanAccount == null && <div>{this.props.whiteLabel.labels['Loading']}</div>}
        {loanAccount != null && (<React.Fragment>
          <h2 className="section-title">{this.props.whiteLabel.labels['Account Details']}</h2>

          <div className="card account-details">
            {(loanAccount) ?
              <dl className="account-details__list">
                {
                  Object.keys(loanAccount.accountDetails).map((kVal) => {
                    if (loanAccount.accountDetails[kVal].type == 6) {
                      if (loanAccount.accountDetails[kVal].value) {
                        return (
                          <div className="account-detatils__item" key={kVal}>
                            <a className="account-details__data" href={loanAccount.accountDetails[kVal].value}>{loanAccount.accountDetails[kVal].label}</a>
                          </div>
                        )
                      }
                    } else if (loanAccount.accountDetails[kVal].type == 7) {
                      if (loanAccount.accountDetails[kVal].value) {
                        return (
                          <div className="account-detatils__item" key={kVal}>
                            <span className="account-details__data hover-pointer" onClick={ () => {
                              if(onShowDocument){
                                onShowDocument(loanAccount.accountDetails[kVal].value);
                              }
                            }}><u>{loanAccount.accountDetails[kVal].label}</u></span>
                          </div>
                        )
                      }
                    } else {
                      return (
                        <div className="account-detatils__item" key={kVal}>
                          <dt className="account-details__data">{loanAccount.accountDetails[kVal].label}</dt>
                          <dd className="account-details__value">{loanAccount.accountDetails[kVal].value}</dd>
                        </div>
                      )
                    }

                  })
                }

              </dl>
              : null
            }
            { (loanAccount.loanStatusText != "" && paidOff === true) ?
              <div className="stamps-red loan-account__impress" style={{position:'relative', marginLeft:'20px'}}> {loanAccount.loanStatusText} </div>
              : null
            }
          </div>
        </React.Fragment>)}
      </div>
    );
  }
}

export default AccountDetails;
