import * as types from '../constants/actions/loan-account';

const defaultState = {
  status: '0',
  isLoading: true,
  sortBy: 1,
  scheduledDocumentList: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_SCHEDULED_DOCUMENT_LIST :
      return {...state, scheduledDocumentList: action.payload, isLoading: false}
    case types.LOADING_SCHEDULED_DOCUMENT_LIST :
      return {...state, scheduledDocumentList: [] , isLoading: true}
    default:
      return state;
  }
}