import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toUSD } from '../../helpers/formatters';
import { paymentFrequency } from '../../helpers/app';
import PayixButton from '../../containers/payix-button';
import moment from 'moment';

// Import assets
import warningIcon from '../../assets/images/warning.png';

class CardTermsConditions extends Component {

  handleSubmit = () => {
    let data = undefined;
    let props = this.props;
    
    if (this.props.isSchedule) {
      data = {
        date: props.scheduleDate,
        frequency: props.frequency,
        amount: props.amount
      }
    }

    this.props.onSubmit(data);
  }

  /**
   * Render additional information when the action is to schedule a payment.
   */
  renderExtraInfoForSchedule() {
    var frequency_label = this.props.whiteLabel.labels[paymentFrequency(this.props.frequency, this.props.paymentFreqs)]
    if (!frequency_label) {
      frequency_label = paymentFrequency(this.props.frequency, this.props.paymentFreqs)
    }
    let markup = (
      <div>
        <div className="form-group form-group--row-layout">
          <p className="size-50per no-margin">{this.props.whiteLabel.labels['Payment Start Date:']} <span className="gothammedium-font">{this.props.scheduleDate}</span></p>
          { 
            (this.props.frequency !== 'onlyonce') 
            ? <p className="size-50per no-margin">{this.props.whiteLabel.labels['Frequency:']} <span className="gothammedium-font">{frequency_label}</span></p>
            : null
          }
        </div>

        { 
          (this.props.frequency !== 'onlyonce' && this.props.useLmsSchedules === false)
          ? <div className="form-group form-group--row-layout">
              <p className="size-50per no-margin">{this.props.whiteLabel.labels['Number of Recurring Payments:']} <span className="gothammedium-font">{this.props.paymentsNumber}</span></p>
            </div>
          : null
        }
      </div>
    );

    return markup;
  }

  /**
   * Render additional information for real time payment.
   */
  renderExtraInfoForRealTimePay() {
    let markup = (
      <div>
        <div className="form-group form-group--row-layout">
          <p className="size-50per no-margin">{this.props.whiteLabel.labels['Payment Date:']} <span className="gothammedium-font">{moment().local().format('YYYY-MM-DD')}</span></p>
        </div>
      </div>
    );
    return markup;
  }


  /**
   * Render the component view.
   */
  render() {
    let cardDisclosure = '';
    if (this.props.isSchedule) {
      cardDisclosure = this.props.disclaimer.borrowerRecurringCardDisclosure;
    } else {
      cardDisclosure = this.props.disclaimer.borrowerOnetimeCardDisclosure;
    }

    return (
      <div>
        <div className="modal__header">
          <div className="modal__header-content--centered">
            <img src={ warningIcon } alt="" />
          </div>
        </div>

        <div className="modal__body">
          <div className="modal__body-content--pad35">
            <p className="ach-terms gothambook-font" dangerouslySetInnerHTML={{__html: cardDisclosure}}></p>

            <div className="form-group form-group--row-layout">
              <p className="size-50per no-margin">{this.props.whiteLabel.labels['Payment Amount:']} <span className="gothammedium-font">{toUSD(this.props.amount)}</span></p>
              <p className="size-50per no-margin">{this.props.whiteLabel.labels['Card Number:']} <span className="gothammedium-font">**{this.props.lastFour}</span></p>
            </div>

            {/**
              ** In case we are making a schedule, we must show the payment date, 
              ** the frequency and the number of recurring payments.
              **/}
            { 
              (this.props.isSchedule)
              ? this.renderExtraInfoForSchedule()
              : this.renderExtraInfoForRealTimePay()
            }

            <div className="form-group form-group--row-layout">
              <PayixButton className="size-50per margin-right-10" onClick={ this.props.onCancel }>
              {this.props.whiteLabel.labels['CANCEL']}
              </PayixButton>

              <PayixButton className="size-50per margin-right-10" onClick={ this.handleSubmit }>
              {this.props.whiteLabel.labels['SUBMIT']}
              </PayixButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paymentFreqs: state.client.paymentFreqs
  }
}
 
export default connect(mapStateToProps)(CardTermsConditions);
