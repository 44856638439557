import React, { Component } from 'react';
import moment from 'moment';
import Datasort from 'react-data-sort';
import { HOST } from '../../constants/api';

class ScheduledDocumentList extends Component {
  /**
   * Render the component view.
   */
  render() {
    return (
      <div className="statement-body">
        {this.props.tableData.length == 0 && <center>{this.props.whiteLabel.labels['No Records found']}</center>}
        {this.props.tableData.length != 0 && <Datasort
          data={this.props.tableData}
          paginate
          render={({ 
            data,
            setSortBy,
            sortBy,
            direction,
            activePage,
            toggleDirection,
            goToPage,
            nextPage,
            prevPage,
            pages
           }) => {
            return <div className="table-responsive-lg">
              <table className="table table-bordered table-hover">
                <thead className="thead-light">
                  <tr>
                    <th className="text-left">{this.props.whiteLabel.labels['Scheduled Date']}</th>
                    <th className="text-left">{this.props.whiteLabel.labels['Next Execution Date']}</th>
                    <th className="text-left">{this.props.whiteLabel.labels['Recurring Group Id']}</th>
                    <th className="text-left">{this.props.whiteLabel.labels['Payment Amount']}</th>
                    <th className="text-left">{this.props.whiteLabel.labels['Recurring Type']}</th>
                    <th className="text-left">{this.props.whiteLabel.labels['View Document']}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(({
                    created_at,
                    next_execution_date,
                    scheduled_recurring_group_id,
                    pmt_amt,
                    repeating_type,
                    id,
                    scheduled_authorization_file
                  }) => (
                      <tr key={id}>
                        <td>{created_at ? moment(created_at.date).format('MM/DD/YYYY') : null}</td>
                        <td>{next_execution_date ? moment(next_execution_date.date).format('MM/DD/YYYY') : null}</td>
                        <td>{(scheduled_recurring_group_id !== null) ? scheduled_recurring_group_id : this.props.whiteLabel.labels['N/A']}</td>
                        <td>{pmt_amt}</td>
                        <td>{repeating_type}</td>
                        <td>{(scheduled_authorization_file === true) ? <a href={`${HOST}/loan/get-scheduled-document-pdf?scheduleId=${id}&loanId=${this.props.selectedLoanId}&accessToken=${localStorage.accessToken}`} target="_blank" className="link-no-style">{this.props.whiteLabel.labels['View']}</a> : this.props.whiteLabel.labels['Not available']}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <Flex style={{justifyContent: 'space-between'}}>
                <GoToPage goToPage={goToPage} pages={pages} whiteLabel={this.props.whiteLabel}/>
                <PageIndicator pages={pages} activePage={activePage} />
                <Navigation
                  activePage={activePage}
                  goToPage={goToPage}
                  nextPage={nextPage}
                  prevPage={prevPage}
                  pages={pages}
                />
              </Flex>
            </div>
          }}
        />}
      </div>
    );
  }
}

function Flex({ children, style }) {
  return <div style={{ display: "flex", ...style }}>{children}</div>;
}

function GoToPage({ goToPage, pages, whiteLabel}) {
  const options = []
  for(let i = 0; i < pages; i++) {
    options.push(<option value={i}>{i + 1}</option>)
  }
  return <div>{whiteLabel.labels['Go to page']} <select onChange={e => goToPage(parseInt(e.target.value))}>{options}</select></div>
}

function Navigation({ activePage, goToPage, nextPage, prevPage, pages }) {
  return (
    <Flex>
      <button disabled={activePage === 0} onClick={() => goToPage(0)}>
        {"<<"}
      </button>
      <button disabled={activePage === 0} onClick={prevPage}>
        {"<"}
      </button>

      <button disabled={activePage === pages - 1} onClick={nextPage}>
        {">"}
      </button>
      <button
        disabled={activePage === pages - 1}
        onClick={() => goToPage(pages - 1)}
      >
        {">>"}
      </button>
    </Flex>
  );
}

function PageIndicator ({pages, activePage}) {
  return <div>
    <b>{activePage + 1}</b> / {pages}
  </div>
} 

export default ScheduledDocumentList;