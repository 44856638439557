import React, { Component } from 'react';
import PayixButton from '../../../containers/payix-button';
import moment from "moment";

// Import assets
// import warningIcon from '../../../assets/images/warning.png';

class Timeout extends Component {

  /**
   * Render the component view.
   */

  constructor(props){
    super(props);
    this.idleTimer = this.props.idleTimer;
    this.state = {
      displaytime: '',
      startTime: moment(),
      logoutAfter: 30
    };
    this.startLogoutTimer = this.startLogoutTimer.bind(this);
    this.stopLogoutTimer = this.stopLogoutTimer.bind(this);
  }

  startLogoutTimer(){
    this.logoutTimer = setInterval(()=>{
      let remainingSec = (this.state.logoutAfter - window.Math.abs(this.state.startTime.diff(moment(),'seconds')));
      this.setState({
        displaytime: remainingSec.toString().padStart(2,'0')
      });
      if(remainingSec <= 0){
        this.stopLogoutTimer();
        this.props.logoutAction();
      }
    },1000);
  }

  stopLogoutTimer(){
    try{
      clearInterval(this.logoutTimer);
    }catch(e){
    }
    this.props.closeModal();
    this.idleTimer.reset();
  }

  componentDidMount(){
    this.startLogoutTimer();
  }

  componentWillUnmount(){
    this.stopLogoutTimer();
  }

  render() { 
    return (
      <div>
        <div className="modal__header">
          <h3 className="modal-title common-title">{this.props.contentTitle}</h3>
        </div>

        <div className="modal__body timeout">
          <div className="modal__body-content--pad35">
            <p className="modal-paragraph">
              {this.props.content} 
            </p>
            <div className="form-group flex-drow flex-center">
              <span className="circle-timer">{this.state.displaytime}</span>
            </div>
            <div className="form-group flex-drow flex-center">
              <PayixButton className="size-50per margin-right-10" onClick={this.stopLogoutTimer}>
                {this.props.whiteLabel.labels['Continue']}
              </PayixButton>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
 
export default Timeout;
