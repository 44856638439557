import React, { Component } from 'react';
import { Application } from '../core/app';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { reset } from 'redux-form';
import { getClientDetails } from '../actions/client';
import StatementList from '../components/statements/list';
import DoximStatementList from '../components/statements/doxim-list';
import { statementListAction, loanUdfUpdate } from '../actions/loan-account';

import { getLoanAccountsInfo } from '../api/loan-account';

// Import actions
import { showLoading, hideLoading, showMessageBar } from '../actions/app';


// Import styles
import '../styles/modules/statements/index.css';

class Statements extends Component {

  state = {
    loanAccounts: [],
    selected: null,
    selectedLoanId: null,
    eStatementStatus: '0',
  }

  componentDidMount() {
    this.fetchInitialData();
  }

  /**
   * Fetch the loan accounts and the payment methods.
   */
  fetchInitialData = () => {
    Promise.all([
      this.getLoanAccounts(),
      this.props.getClientDetails()
    ]).then(() => {
      this.props.statementListAction(this.state.selectedLoanId);
    })
      .catch(() => {
        Application.showInternalError();
      });
  };

  /**
   * Fetch the loan accounts.
   */
  getLoanAccounts(loanId) {
    return getLoanAccountsInfo()
      .then((response) => {
        let data = response.data;
        
        if (data.status === 1 && data.statusCode === 200) {
          let loans = data.result.loans;
          let eStatementStatus = '0';
          if (loans.length > 0) {
            let selectedLoan = loans[0];
            if (loanId) {
              for (let i in loans) {
                if (loans[i].loan.id === loanId) {
                  selectedLoan = loans[i];
                  break;
                }
              }
            }

            // Check if eStatement is on or off from udf32 which is set on e-statement
            eStatementStatus = selectedLoan.loan.eStatement;

            this.setState({
              loanAccounts: loans,
              selected: 0,
              selectedLoanId: selectedLoan.loan.id,
              eStatementStatus: eStatementStatus,
            });
          }
        } else {
          this.props.showMessageBar({
            status: 'error',
            text: data.message
          });
        }
      })
      .catch(() => {
        Application.showInternalError();
      });
  }

  


  /**
   * Handle the event when the user change the loan account.
   * 
   * @param event
   */
  handleChangeLoanAccount = (event) => {
    if (this.props.statements.isLoading == false) {
      this.state.selectedLoanId = parseInt(event.target.value);
      this.props.statementListAction(parseInt(event.target.value));

      // Call Get Loan Details to get the updated E-statement status
      this.getLoanAccounts(parseInt(event.target.value)); 
    }

  }

  /**
   * Handle the event when the user change the loan account.
   * 
   * @param event
   */
   handleChangeEStatement = (event) => {
    if (this.props.statements.isLoading == false) {
        this.props.loanUdfUpdate(this.state.selectedLoanId, parseInt(event.target.value));
    }

  }

  /**
   * Render the component view.
   */
  render() {
    let url = this.props.match.url;
    let { loanAccounts } = this.state;
    return (
      <div className="content">
        <div className="flex-block flex-block--space-between">
          <div className="contact-info__loan-statements">
            <label className="contact-info__loan-accounts-label">{this.props.whiteLabel.labels['Select Account']}</label>
            <select
              className="custom-select-in"
              onChange={this.handleChangeLoanAccount}>

              {loanAccounts.map((loanAccount) =>
                <option key={loanAccount.id} value={loanAccount.loan.id}>
                  {loanAccount.loan.loanNo}
                </option>
              )}
            </select>
          </div>
          {this.props.client.doximEStatementsSwitch == 1 
          ? ( <React.Fragment>
            {this.props.statements.isLoading == false && (<div className="contact-info__loan_estatement">
                <label className="contact-info__loan-accounts-label">{this.props.whiteLabel.labels['E-statement']}</label>
                <select className="custom-select-in" onChange={this.handleChangeEStatement}>
                    <option selected={this.state.eStatementStatus == '1' ? 'selected' : false} value='1'>{this.props.whiteLabel.labels['On']}</option>
                    <option selected={this.state.eStatementStatus == '0' ? 'selected' : false} value='0'>{this.props.whiteLabel.labels['Off']}</option>
                </select>
            </div>)}
          </React.Fragment> ) : ''}
        </div>
        <div className="app-statements">
          {this.props.statements.isLoading == true && (<div className="loader-in"> </div>)}
          {this.props.statements.isLoading == false && this.props.client.enableDoximStatements == 0 && (<StatementList whiteLabel={this.props.whiteLabel} selectedLoanId={this.state.selectedLoanId} tableData={this.props.statements.statementList} />)}
          {this.props.statements.isLoading == false && this.props.client.enableDoximStatements == 1 && (<DoximStatementList whiteLabel={this.props.whiteLabel} selectedLoanId={this.state.selectedLoanId} tableData={this.props.statements.statementList} />)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    whiteLabel: state.whiteLabel,
    statements: state.statements,
    client: state.client.data
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    showLoading, hideLoading, showMessageBar, reset, statementListAction, loanUdfUpdate, getClientDetails
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Statements));