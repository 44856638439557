import React, { Component } from 'react';

// Import components
import { Field, reduxForm } from 'redux-form';
import PayixButton from '../../containers/payix-button';

const validate = values => {
  const errors = {};
  if (!values.language || values.language < 1) {
    errors.language = 'Please, select a Language';
  }

  return errors;
};

/**
   * Render the Languages Field.
   */
  
const renderLanguageSelect = ({
  input,
  label,
  htmlFor,
  languages,
  whiteLabel,
  meta: { touched, error }
}) => {
  let selectLbl = whiteLabel.labels['Select'];
  let options = languages.map((s, i) => (
    (<option key={s.code} value={s.code}>{s.name}</option>)
  ));
  input.defaultValue = JSON.parse(localStorage.getItem('userData'))['languageCode'] || 'en';
  options = options.filter(a => {return a.key != ''});
  return (
    <div className="form-group">
      <label htmlFor={htmlFor} className="form-group__label">
        {label}
      </label>
      <select className="form-group__input" id={htmlFor} {...input} >
        {options}
      </select>
      {touched && error && <span className="form-group__error">{error}</span>}
    </div>
  );
};

class ChangeLanguageForm extends Component {
  /**
   * Render the component view.
   */
  render() {
    let { handleSubmit } = this.props;

    return (
      <React.Fragment>
        <h2 className="settings-card__title">{this.props.whiteLabel.labels['Change Language']}</h2>
        <hr className="hr" />

        <form onSubmit={handleSubmit}>
            {/* Languages */}
              <Field
                name="language_code"
                type="text"
                htmlFor="language"
                languages={this.props.languages}
                whiteLabel={this.props.whiteLabel}
                component={renderLanguageSelect}
                label={this.props.whiteLabel.labels['Select Language']}
                autoComplete="off"
              />
          <div className="form-group">
            <PayixButton className="button--full" type="submit">
                {this.props.whiteLabel.labels['Save Changes']}
            </PayixButton>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

ChangeLanguageForm = reduxForm({
  form: 'changeLanguageForm',
  validate
})(ChangeLanguageForm)
 
export default ChangeLanguageForm;