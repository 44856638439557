import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { isMobilePhone } from 'validator';

// Import components
import { Field, reduxForm } from 'redux-form';
import PayixButton from '../../containers/payix-button';

const validate = (values, _parent) => {
  const errors = {};
  try {
      values.phone = values.phone.replace(/[^0-9]/gi, '')
      var result = values.phone.substring(1)
      if (!result || !isMobilePhone('' + result, 'en-US')) {
          errors.phone = _parent.whiteLabel.labels['Invalid Phone Number'];
      }
  } catch (e) {
      if(!values.phone){
        errors.phone = _parent.whiteLabel.labels['Invalid Phone Number'];
      }else {
        errors.phone = e.toString();
      }
  }

  return errors;
};

const renderField = ({input, type, name, maxLength, meta: { touched, error }}) => (
  <div>
    <InputMask mask="+1 999-999-9999" className="form-group__input" id={name} {...input} type={type}></InputMask>
    {/* <input className="form-group__input" id={name} {...input} maxLength={ maxLength } type={type} /> */}
    { touched && error && <span className="form-group__error">{error}</span> }
  </div>
);

class AddPhoneForm extends Component {
  /**
   * Render the component view.
   */
  render() {
    let { handleSubmit, valid } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="form-group__label" htmlFor="phone">{ this.props.whiteLabel.labels['Phone Number'] }</label>
          <Field name="phone" type="text" maxLength="100" component={ renderField } />
        </div>

        <div className="form-group">
          <PayixButton className="button--full" type="submit" disabled={!valid}>
            {this.props.whiteLabel.labels['Submit']}
          </PayixButton>
        </div>
      </form>
    )
  }
}

AddPhoneForm = reduxForm({
  form: 'addPhoneForm',
  validate
})(AddPhoneForm);
 
export default AddPhoneForm;