import React, { Component } from 'react';

class LoanNumber extends Component {
  /**
   * Method constructor.
   */
  constructor(props) {
    super(props);

    this.state = {
      selected: props.loanAccounts
    }
  }

  /**
   * Check if a checkbox is a checked.
   * 
   * @param {*} loanNo 
   */
  isChecked(loanNo) {
    let pos = this.state.selected.indexOf(loanNo);

    if (pos === -1) {
      return false;
    }

    return true;
  }

  /**
   * Handle the event when a checkbox is selected.
   * 
   * @param e
   */
  handleSelectItem = (e) => {
    let loanNo = e.target.dataset.account;
    let selected = Object.assign([], this.state.selected);

    // If account is checked remove it from the selected accounts array, else add it.
    if (this.isChecked(loanNo)) {
      let pos = selected.indexOf(loanNo);
      selected.splice(pos, 1);
    } else {
      selected.push(loanNo);
    }

    this.setState({selected});
    this.props.onSelectedItem(selected);
  }

  /**
   * Render the component view.
   */
  render() {
    return (
      <div className="form-group form-group--horizontal checkbox-inline">
        <span className="form-group__label">{this.props.whiteLabel.labels['Loan Number']}</span>

        <div className="flex-block loan-filter">
        { this.props.loanAccounts.map(loanAccount => 
          <label className="checkbox checkbox-inline__label" key={ loanAccount }>
            { loanAccount }
            <input type="checkbox" defaultChecked={true} data-account={ loanAccount } 
              onChange={ this.handleSelectItem } />
            <span className="checkmark"></span>
          </label>
        ) }
        </div>
      </div>
    );
  }
}
 
export default LoanNumber;