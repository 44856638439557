import React, { Component } from 'react';
import { STRONG_PASSWORD } from '../../constants/regex';

// Import components
import { reduxForm } from 'redux-form';
import PayixButton from '../../containers/payix-button';
import Modal from '../application/modal';
import InfoModal from '../application/modals/info-modal';

class DeleteAccountForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      modalWidth: '500px',
    };
  }

  beforeDeleteAccount = (e) => {
    e.preventDefault();
    this.setState({
        isModalOpen: true
    });
  }

  confirmDeleteAccount = () => {
    this.setState({
        isModalOpen: false
    });
    this.props.handleSubmit();
  }

  /**
   * Close the modal.
   */
  closeModal = () => {
    if (this.state.isModalOpen) {
      this.setState({
        isModalOpen: false
      }); 
    }
  }

  /**
   * Render the component view.
   */
  render() {
    return (
      <React.Fragment>
        <h2 className="settings-card__title">{this.props.whiteLabel.labels['Delete Account Title']}</h2>
        <hr className="hr" />

        <form onSubmit={this.beforeDeleteAccount}>
          
          <div className="form-group">
            <h4 className="form-group__label">{this.props.whiteLabel.labels['Delete Account Description']}</h4>
          </div>

          <div className="form-group">
            <PayixButton className="button--full" type="submit">
                {this.props.whiteLabel.labels['Delete Account Button']}
            </PayixButton>
          </div>
        </form>
        <Modal
          isOpen={this.state.isModalOpen}
          width={this.state.modalWidth}
          onClose={this.closeModal}
        >
          <InfoModal
              content={this.props.whiteLabel.labels['Delete Account Description Confirm']}
              leftButtonHandler={this.closeModal}
              rightButtonHandler={this.confirmDeleteAccount}
              whiteLabel={this.props.whiteLabel}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

DeleteAccountForm = reduxForm({
  form: 'deleteAccountForm'
})(DeleteAccountForm)
 
export default DeleteAccountForm;