import React, { Component } from 'react';
import { connect } from 'react-redux';

class PayixButton extends Component {
  /**
   * Render the component view.
   */
  render() {
    let { color, className, disabled, type, children, onClick, customStyles } = this.props;

    let styles = {
      backgroundColor: color,
      ...customStyles
    }

    return (
      <button className={`button ${className}`} style={styles} type={type} 
        onClick={onClick} disabled={disabled}>
        {children}
      </button>
    )
  }
}

PayixButton.defaultProps = {
  className: '',
  type: 'button'
}

const mapStateToProps = (state) => {
  return {
    color: state.client.data.colorCode
  }
}

export default connect(mapStateToProps, {})(PayixButton);