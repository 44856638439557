import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class Menu extends Component {
  /**
   * Render the component view.
   */
  render() {
    let url = this.props.url;

    return (
      <ul className="settings-menu">
        <li className="settings-menu__item">
          <NavLink 
            to={`${url}/edit-profile`} 
            className="settings-menu__item-link" 
            activeClassName="color-green">
            <i className="fas fa-user settings-menu__item-icon"></i>{this.props.whiteLabel.labels['Contact Information']}
          </NavLink>
        </li>

        <li className="settings-menu__item">
          <NavLink 
            to={`${url}/payment-option`}
            className="settings-menu__item-link" 
            activeClassName="color-green">
            <i className="fas fa-credit-card settings-menu__item-icon"></i>{this.props.whiteLabel.labels['Payment Options']}
          </NavLink>
        </li>
        
        <li className="settings-menu__item">
          <NavLink 
            to={`${url}/change-password`} 
            className="settings-menu__item-link" 
            activeClassName="color-green">
            <i className="fas fa-lock settings-menu__item-icon"></i>{this.props.whiteLabel.labels['Change Password']}
          </NavLink>
        </li>
        {this.props.languages.length > 1 && (
          <li className="settings-menu__item">
            <NavLink 
              to={`${url}/change-language`} 
              className="settings-menu__item-link" 
              activeClassName="color-green">
              <i className="fas fa-language settings-menu__item-icon"></i>{this.props.whiteLabel.labels['Change Language']}
            </NavLink>
          </li>
        )}
        <li className="settings-menu__item">
            <NavLink 
              to={`${url}/delete-account`} 
              className="settings-menu__item-link" 
              activeClassName="color-green">
              <i className="fas fa-user-minus settings-menu__item-icon"></i>{this.props.whiteLabel.labels['Delete Account']}
            </NavLink>
          </li>
      </ul>
    );
  }
}
 
export default Menu;