import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toUSD } from '../../helpers/formatters';
import { paymentFrequency } from '../../helpers/app';
import PayixButton from '../../containers/payix-button';

// Import assets
import warningIcon from '../../assets/images/warning.png';

class PadTermsConditions extends Component {
  handleSubmit = () => {
    let data = undefined;
    let props = this.props;

    if (this.props.isSchedule) {
      data = {
        date: props.scheduleDate,
        frequency: props.frequency,
        amount: props.amount
      }
    }

    this.props.onSubmit(data);
  }

  /**
   * Render additional information when the action is to schedule a payment.
   */
  renderExtraInfoForSchedule() {
    var frequency_label = this.props.whiteLabel.labels[paymentFrequency(this.props.frequency, this.props.paymentFreqs)]
    if (!frequency_label) {
      frequency_label = paymentFrequency(this.props.frequency, this.props.paymentFreqs)
    }
    let markup = (
      <div>
        <div className="form-group form-group--row-layout">
          <p className="size-50per no-margin">{this.props.whiteLabel.labels['Payment Start Date:']} <span className="gothammedium-font">{this.props.scheduleDate}</span></p>
          { 
            (this.props.frequency !== 'onlyonce') 
            ? <p className="size-50per no-margin">{this.props.whiteLabel.labels['Frequency:']} <span className="gothammedium-font">{frequency_label}</span></p>
            : null
          }
        </div>

        { 
          (this.props.frequency !== 'onlyonce' && this.props.useLmsSchedules === false) 
          ? <div className="form-group form-group--row-layout">
              <p className="size-50per no-margin">{this.props.whiteLabel.labels['Number of Recurring Payments:']}  <span className="gothammedium-font">{this.props.paymentsNumber}</span></p>
            </div>
          : null
        }
      </div>
    );

    return markup;
  }

  /**
   * Render the component view.
   */
  render() {
    let { company } = this.props;
    return (
      <div>
        <div className="modal__header">
          <div className="modal__header-content--centered">
            <img src={ warningIcon } alt="" />
          </div>
        </div>

        <div className="modal__body">
          <div className="modal__body-content--pad35">
            <p className="ach-terms gothambook-font">
              By clicking on <span className="gothammedium-font">“Submit”</span> I agree to each of the following: 
              <br /><br />
              I hereby authorize <span className="gothammedium-font">{company.name} ({company.website})</span> to initiate an electronic withdrawal 
              from the bank account or an electronic transaction to the debit card set forth below, in such amount 
              and on such date as set forth below. If I am setting up a payment schedule (recurring transactions), 
              I authorize the electronic bank account withdrawals or debit card transactions for the amount and with such frequency 
              set forth below. I understand that if this is a one-time transaction submitted after 5:00 PM eastern time, 
              it may have an effective date of the next business-banking day and may show as a withdrawal from my account on that date. 
              <br /><br />
              These debits are for personal purposes.
              <br /><br />
              If the debit (or if I am authorizing a payment schedule, any future debit) is dishonoured by my financial institution 
              or card issuer for any reason, including without limitation insufficient funds, I understand 
              and agree that <span className="gothammedium-font">{company.name}</span>, is entitled to issue another debit in substitution for the dishonoured debit. 
              I also agree that a Returned Payment Fee due to insufficient funds may apply and be charged separately. 
              <br /><br />
              <strong>I understand that <span className="gothammedium-font">{company.name}</span>.
              will not notify me in advance of any withdrawal and 
              I agree to waive all pre-notification requirements in respect of all withdrawals to be drawn
               under this Authorization. <span className="gothammedium-font">{company.name}</span>. may assign this Authorization, whether directly or
              indirectly, by operation of law, change of control, or otherwise.</strong>
              <br /><br />
              If I am setting up a future payment or a payment schedule, 
              this Authorization is to remain in effect unless I notify <span className="gothammedium-font">{company.name}</span>
              in writing of its cancellation. This notification must be received at least thirty (30) business days before
              the next Scheduled Debit Date via email to <span className="gothammedium-font">{company.email}</span>. However, if I cancel this Authorization,
              I remain obligated to pay to <span className="gothammedium-font">{company.name}</span> all amounts due or owing under my Payment Agreement.
              I am aware that I may obtain a sample cancellation form, or more information on my right to cancel this Authorization at
              my financial institution or by visiting <a href='https://www.payments.ca'>www.payments.ca</a>.  I represent and warrant that all persons whose approval 
              are required to consent in respect of the account below (or any replacement account) have approved this Authorization.
              <br /><br />
              I am aware that I have certain recourse rights if any debit does not comply with this Authorization.
              For example, I have the right to receive reimbursement for any debit that is not authorized or is not consistent with this Authorization.
               I understand that to obtain more information on my recourse rights, I may contact my financial institution or visit <a href='https://www.payments.ca'>www.payments.ca</a>
            </p>

            <div className="form-group form-group--row-layout">
              <p className="size-50per no-margin">{this.props.whiteLabel.labels['Payment Amount:']} <span className="gothammedium-font">{toUSD(this.props.amount)}</span></p>
              <p className="size-50per no-margin">{this.props.whiteLabel.labels['Bank Account:']} <span className="gothammedium-font">**{this.props.lastFour}</span></p>
            </div>

            {/**
              ** In case we are making a schedule, we must show the payment date, 
              ** the frequency and the number of recurring payments.
              **/}
            { 
              (this.props.isSchedule)
              ? this.renderExtraInfoForSchedule()
              : null
            }

            <div className="form-group form-group--row-layout">
              <PayixButton className="size-50per margin-right-10" onClick={ this.props.onCancel }>
                  {this.props.whiteLabel.labels['Cancel']}
              </PayixButton>

              <PayixButton className="size-50per margin-right-10" onClick={ this.handleSubmit }>
                  {this.props.whiteLabel.labels['Submit']}
              </PayixButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paymentFreqs: state.client.paymentFreqs
  }
}
 
export default connect(mapStateToProps)(PadTermsConditions);