import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

// Import components
import PayixButton from '../../containers/payix-button';

const validate = (values, _parent) => {
  const errors = {};

  if (values.state === 'select' || values.state === 'SEL') {
    errors.state = _parent.whiteLabel.labels['This field is required'];
  }

  if (!values.streetAddress) {
    errors.streetAddress = _parent.whiteLabel.labels['This field is required'];
  }

  if (!values.city) {
    errors.city = _parent.whiteLabel.labels['This field is required'];
  }

  if (!values.zipcode) {
    errors.zipcode = _parent.whiteLabel.labels['This field is required'];
  }

  return errors;
};

const renderField = ({input, type, name, maxLength, meta: { touched, error }}) => (
  <div>
    <input className="form-group__input" id={name} {...input} maxLength={ maxLength } type={type} />
    { touched && error && <span className="form-group__error">{error}</span> }
  </div>
)

const renderSelect = ({input, name, meta: { touched, error }, children}) => (
  <div>
    <select className="form-group__input" {...input}>
      { children }
    </select>
    { touched && error && <span className="form-group__error">{error}</span> }
  </div>
)

class EditContactInformationForm extends Component {
  /**
   * Render the component view.
   */
  render() { 
    let { handleSubmit, valid, states } = this.props;
    states.shift();
    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="form-group__label">{this.props.whiteLabel.labels['Street Number and Name']}</label>
          <Field name="streetAddress" type="text" component={ renderField } />
        </div>

        <div>
          <div className="half-responsive">
            <div className="form-group">
              <label className="form-group__label">{this.props.whiteLabel.labels['Unit Number']}</label>
              <Field name="unitNumber" type="text" component={ renderField } />
            </div>
          </div>
          <div className="half-responsive">
            <div className="form-group">
              <label className="form-group__label">{this.props.whiteLabel.labels['City']}</label>
              <Field name="city" type="text" component={ renderField } />
            </div>
          </div>
        </div>

        <div>
          <div className="half-responsive">
            <div className="form-group">
              <label className="form-group__label">{this.props.whiteLabel.labels['State']}</label>
              <Field name="state" type="text" component={ renderSelect }>
                {states.map(state => 
                  <option key={state.stateCode} value={state.stateCode}>
                    {state.stateName}
                  </option>
                )}
              </Field>
            </div>
          </div>
          <div className="half-responsive">
            <div className="form-group">
              <label className="form-group__label">{this.props.whiteLabel.labels['Postal code']}</label>
              <Field name="zipcode" type="text" component={renderField} />
            </div>
          </div>
        </div>

        <div className="form-group">
          <PayixButton className="button--full" type="submit" disabled={!valid}>
              {this.props.whiteLabel.labels['Save Changes']}
          </PayixButton>
        </div>
      </form>
    )
  }
}


EditContactInformationForm = reduxForm({
  form: 'editContactInformationForm',
  validate
})(EditContactInformationForm)
 
export default EditContactInformationForm;