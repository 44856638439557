import React, { Component } from 'react';
import onClickOutside from "react-onclickoutside";
import { toCardNumber, toAchNumber, toAchType } from '../../helpers/formatters';
import bankImg from '../../assets/images/bank.png';
import { connect } from 'react-redux';

// Import components
import DropDownMenu from './drop-down-menu';
import DropDownMenuItem from './drop-down-menu/item';

class PaymentMethods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownCardsDisplayed: false,
    };
    this.paymentMethod = null;
  }

  /**
   * Display or hidden the loan account options dropdown.
   */
  displayCardsDdown = () => {
    this.setState((prevState) => {
      return {
        isDropdownCardsDisplayed: !prevState.isDropdownCardsDisplayed
      }
    });
  }

  handleClickOutside = () => {
    if (this.state.isDropdownCardsDisplayed) {
      this.displayCardsDdown();
    }
  };

  /**
   * Handle the event when select a card.
   * 
   * @param key The setted key for the payment method.
   */
  handleChangeEvent = (key) => {
    this.displayCardsDdown();

    if (key === '') {
      this.props.onAddPaymentMethod();
    }

    if (Number.isInteger(key)) {
      if (this.props.syncWithStore) {
        // Get the info of the selected payment method and extract the brand.
        let paymentMethod = this.props.paymentMethods[key];
        let brand = paymentMethod.cardBrand;

        if (paymentMethod.paymentType === 2 || paymentMethod.paymentType === 3) {
          brand = paymentMethod.bankName;
        }

        this.props.setPaymentMethod(brand, this.props.loanAccount, paymentMethod.paymentType, null, paymentMethod.id);
      } else {
        this.props.onChange(key - 1);
      }
    }
  }

  /**
   * Get the image associate to the payment method.
   * 
   * @param paymentMethod
   */
  getImage(paymentMethod) {
    if (paymentMethod.paymentType === 2 || paymentMethod.paymentType === 3) return bankImg;
    return paymentMethod.cardImage;
  }

  /**
   * Get the name associate to the payment method.
   * 
   * @param {*} paymentMethod 
   */
  getName(paymentMethod) {
    if (paymentMethod.paymentType === 2 || paymentMethod.paymentType === 3) return paymentMethod.bankName;
    return paymentMethod.name;
  }

  /**
   * Get the number associate to the payment method.
   * 
   * @param {*} paymentMethod 
   */
  getNumber(paymentMethod) {
    if (paymentMethod.paymentType === 2 || paymentMethod.paymentType === 3) return toAchNumber(paymentMethod.lastFour);
    return toCardNumber(paymentMethod.lastFour);
  }

    /**
   * Get the account-type if it's an ACH associate to the payment method.
   * 
   * @param {*} paymentMethod 
   */
  getAchType(paymentMethod) {
    if (paymentMethod.paymentType === 2) {
      let paymentTypeText = toAchType(paymentMethod.accountType);
      return this.props.whiteLabel.labels[paymentTypeText];
    }
  }

  /**
   * Render the selected payment type.
   */
  renderSelectedPaymentMethod() {
    let image = this.getImage(this.props.selected);
    let name = this.getName(this.props.selected);
    let number = this.getNumber(this.props.selected);
    let achType = this.getAchType(this.props.selected);

    return (
      <div className="loan-account_payment-method-selected">
        <span className="payment-method__image-wrapper">
          <img src={image} alt="" />
        </span>

        <p className="loan-account__selected-desc main-color">
          {name}<br />
          <span className="gothammedium-font">{number} {achType}</span>
        </p>
      </div>
    )
  }

  /**
   * Render a specific payment type.
   * 
   * @param paymentType
   */
  renderPaymentType(paymentType, key) {
    let image = this.getImage(paymentType);
    let name = this.getName(paymentType);
    let number = this.getNumber(paymentType);
    let achType = this.getAchType(paymentType);

    return (
      <DropDownMenuItem value={key} key={key} onClick={this.handleChangeEvent}>
        <div className="loan-account_payment-method-item">
          <span className="payment-method__image-wrapper">
            <img src={image} alt="" />
          </span>

          <p className="loan-account_payment-method-desc">
            {name}<br />{number} {achType}
          </p>
        </div>
      </DropDownMenuItem>
    )
  }

  /**
   * Render the component view.
   */
  render() {
    return (
      <div className="relative">
        <button className="loan-account_payment-method" type="button" onClick={this.displayCardsDdown}>
          {this.props.selected ? this.renderSelectedPaymentMethod() :  this.props.whiteLabel.labels['SELECT PAYMENT METHOD']}
          <i className="fas fa-caret-down loan-account__payment-method-display-btn" />
        </button>

        <DropDownMenu
          className="loan-account__payment-method-items"
          isDisplayed={this.state.isDropdownCardsDisplayed}>
          
          {this.props.paymentMethods.map(
            (paymentType, index) => this.renderPaymentType(paymentType, (index + 1))
          )}

          {/**
            ** We only show the button if you have less than 5 methods of payment and is allowed show it.
            **/}
          {(this.props.paymentMethods.length < 5 && this.props.allowAddNew) ? (
            <DropDownMenuItem value="" onClick={this.handleChangeEvent}>
              <div className="loan-account_payment-method-item loan-account_payment-method-item--green">
                <span className="payment-method__image-wrapper">
                  <i className="fas fa-plus-circle" />
                </span>
                <p className="loan-account_payment-method-desc">
                  {this.props.whiteLabel.labels['Add New Payment Method']}
                </p>
              </div>
            </DropDownMenuItem>
          ) : null}
        </DropDownMenu>
      </div>
    );
  }
}

PaymentMethods.defaultProps = {
  allowAddNew: true,
  syncWithStore: true
};

const mapStateToProps = (state) => {
  return {
    whiteLabel: state.whiteLabel
  };
};

export default connect(mapStateToProps)(onClickOutside(PaymentMethods));
