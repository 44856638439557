import React, { Component } from 'react';
import PayixButton from '../../../containers/payix-button';
import { isEmail } from 'validator';

class ChangeEmailModal extends Component {
  constructor(props) {
      super(props);
      this.state = {
          new_email: '',
          error_email: false,
          error_email_message: ''
      };
  }
  /**
   * Handler for on change event on the new_email field.
   * 
   * @param event
   */
  handleEmail = (event) => {
      if (!isEmail(event.target.value) && event.target.value != '') {
          this.setState({error_email_message: this.props.whiteLabel.labels['The email address is invalid'], error_email: true});
      } else if (event.target.value === this.props.email) {
          this.setState({error_email_message: this.props.whiteLabel.labels['This email address is the same as the previous'], error_email: true});
      } else {
          this.setState({new_email: event.target.value, error_email_message: '', error_email: false});
      }
      
  }
  /**
   * Render the component view.
   */
  render() {
    return (
      <div>
        <button className="modal__close" type="button" onClick={this.props.onCancel}><i className="fas fa-times"></i></button>
        <div className="modal__header">
          <div className="modal__header-content--centered">
            <div className="modal-header-text">
                {this.props.whiteLabel.labels['Email already saved']}:<span className="block gothammedium-font">{this.props.email}</span>
                <br></br>
                {this.props.whiteLabel.labels['Change email address?']}
                <input 
                className="form-group__input" 
                type="text" 
                maxLength="50"
                onChange={this.handleEmail} />
                {(this.state.error_email) ? <span className="color-red">{this.state.error_email_message}</span> : ''}
            </div>
          </div>
        </div>
        
        <div className="modal__body d-flex justify-content-center">
            <div className="congratulations-modal__body w-100">
                <PayixButton className="button--full" onClick={this.props.onCancel} customStyles={{fontFamily: 'GothamBook'}}>
                    {this.props.whiteLabel.labels['Keep email']}
                </PayixButton>
            </div>
            <div className="congratulations-modal__body w-100">
                <PayixButton className="button--full" onClick={this.props.onNext(this.state.new_email)} disabled={this.state.error_email} customStyles={{fontFamily: 'GothamBook'}}>
                    {this.props.whiteLabel.labels['Replace email']}
                </PayixButton>
            </div>
        </div>
      </div>
    );
  }
}

export default ChangeEmailModal;
