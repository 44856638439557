import React, { Component } from 'react';

// Import components
import DropDownMenu from '../drop-down-menu';
import DropDownMenuItem from '../drop-down-menu/item';

class Header extends Component {
  state = {
    isDropdownAccountsDisplayed: false
  }

  /**
   * Display or hidden the accounts dropdown.
   */
  displayAccountsDropdown = () => {
    let status = !this.state.isDropdownAccountsDisplayed;

    this.setState({
      isDropdownAccountsDisplayed: status
    });
  }

  /**
   * Handle the event when select a new account.
   * 
   * @param value The value for the item clicked.
   */
  handleChangeAccountEvent = (value) => {
    this.displayAccountsDropdown();
    this.props.onChangeLoanAccount(value);
    window.location.reload();
  }

  /**
   * Render the button than display the add loan account modal.
   */
  renderAddLoanAccountModal() {
    if(this.props.hideLinkButton){
      return null;
    }
    if (this.props.loanAccounts.length < this.props.linkLoanLimitInBp) {
      return (
        <button 
          className="button button--small button--green" 
          onClick={this.props.onLinkLoanAccount}>
          {this.props.whiteLabel.labels['Add']}&nbsp;
          <i className="fas fa-plus-circle"></i>
        </button>
      );
    }

    return null;
  }

  /**
   * Render the component view.
   */
  render() {
    let { loanAccounts, loanNo } = this.props;

    return (
      <header className="loan-account__top-box">
        {/**
         ** Title & Add loan account button
         **/}
        <div className="grow-1">
          <h2 className="section-title">
          {this.props.whiteLabel.labels['Link Accounts']}
          </h2>
          {loanAccounts.length < this.props.linkLoanLimitInBp
            ? this.renderAddLoanAccountModal()
            : null
          }
        </div>

        <div className="loan-account__accounts-dropdown-wrapper">
          <button
            className="loan-account__accounts-dropdown"
            type="button"
            onClick={this.displayAccountsDropdown}>
            {loanNo}
            <i className="fas fa-caret-down loan-account__accounts-dropdown-caret" />
          </button>

          <DropDownMenu
            isDisplayed={this.state.isDropdownAccountsDisplayed}
            side="right">
            {loanAccounts.map(loanAccount => (
              <DropDownMenuItem
                key={loanAccount.id}
                value={loanAccount.id}
                onClick={this.handleChangeAccountEvent}>
                <span className="loan-account__accounts-dropdown-item loan-account__accounts-dropdown-item--actives">
                  {loanAccount.loanNo}
                </span>
              </DropDownMenuItem>
            ))}
          </DropDownMenu>
        </div>
      </header>
    );
  }
}

export default Header;