import React, { Component } from 'react';
// import { toUSD } from '../../helpers/formatters';

// Import assets
import successIcon from '../../assets/images/checked.png';

// Import components
import PaymentReceipt from './receipt';
import SendReceiptForm from './send-receipt-form';
import {connect} from 'react-redux';

class SuccessPaymentNotice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      details: null,
      email: '',
      phoneNo: '',
    };

    let data = JSON.parse(this.props.data);

    this.state.email = data.installEmailAddress.emailAddrText;
    this.state.phoneNo = data.installMobilePhone.mobilePhoneNo;
  }

  /**
   * Form to send the receipt to email or phone number 
   */
   renderSendReceiptForm = () => {
    let initialValues = {email: this.state.email, phoneNo: this.state.phoneNo};
    let markup = '';

    // Show send receipt form if orderId is not available in case of ACH payment when oneTimeAchImportNls is enabled
    if (typeof this.props.payment.paymentToken.orderId === 'number') {
        markup = (
            <SendReceiptForm initialValues={initialValues} enableReinitialize={true} onSubmit={ this.handleSendReceipt } whiteLabel={this.props.whiteLabel}/>
        );
    }

    return markup;
   }

  /**
   * Handle the send receipt.
   * 
   * @param values The form values.
   */
  handleSendReceipt = (values) => {
    values.receiptId = this.props.payment.paymentToken.orderId;
    this.props.onSendReceipt(values);
  }

  renderLeftContent = () => {
    let brand = '';
    let lastFour = '';
    let payment = this.props.payment;

    // Override the payment method from achDetails if exists in response.
    if (payment.achDetails) {
      brand = payment.achDetails.bankName;
      lastFour = payment.achDetails.lastFour;
    } else if (payment.padDetails) {
      brand = payment.padDetails.bankName;
      lastFour = payment.padDetails.lastFour;
    } else {
      brand = payment.cardDetails.cardBrand;
      lastFour = payment.cardDetails.lastFour;
    }

    return <React.Fragment>
      <div className="receipt__data-section">
        <p className="receipt__value--payment">{payment.loanDetails.loanNo}</p>
        <span className="receipt__label--payment">{this.props.whiteLabel.labels['Loan Number']}</span>
      </div>

      <div className="receipt__data-section">
        <p className="receipt__value--payment">{payment.paymentToken.confirmId}</p>
        <span className="receipt__label--payment">{this.props.whiteLabel.labels['Auth Code']}</span>
      </div>

      <div className="receipt__data-section">
        <p className="receipt__value--payment">{payment.paymentToken.orderId}</p>
        <span className="receipt__label--payment">{this.props.whiteLabel.labels['Confirmation Number']}</span>
      </div>

      <div className="receipt__data-section">
        <p className="receipt__value--payment" title={brand}>{brand}</p>
        <span className="receipt__label--payment">**{lastFour}</span>
      </div>
    </React.Fragment>;
  };

  /**
   * Render the component view.
   */
  render() {
    return (
      <div>
        <div className="modal__header">
          <div className="modal__header-content--centered">
            <img src={ successIcon } alt="" />

            <p className="modal-paragraph margin-top-20">
              <span className="gothammedium-font">{this.props.whiteLabel.labels['Receipt Title']}</span><br />
              {this.props.whiteLabel.labels['Receipt Message']}
            </p>
          </div>
        </div>

        <div className="modal__body">
          <div className="modal__body-content--pad35">
            <PaymentReceipt 
              date={ this.props.payment.paymentToken.date }
              dateClassName="receipt__date--payment"
              leftContent={this.renderLeftContent()}
              amount={this.props.payment.paymentToken.paidAmount}
              whiteLabel={this.props.whiteLabel} />

            { this.renderSendReceiptForm() }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.client.data,
    data: localStorage.getItem('userData')
  }
};

export default connect(mapStateToProps)(SuccessPaymentNotice);
